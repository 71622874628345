import React, { useEffect, useContext } from "react";
import "css/Footer.scss";
import { Link } from "react-router-dom";
import FooterHomeContent from "./FooterHomeContent";
import FooterBrandContent from "./FooterBrandContent";
import { useImmer } from "use-immer";
import Axios from "axios";

import BrandStateContext from "contexts/BrandStateContext";
import FormLoader from "./FormLoader/FormLoader";

function Footer(props) {
  const [formValues, setFormValues] = useImmer({
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    contact: { value: "", error: "" },
    message: { value: "", error: "" },
    recipient: null,
    isSubmitting: false,
    resultMessage: "",
    resultStatus: "",
  });

  /**
   * Get the state for the current brand
   * if homepage brandstate will equal false
   */
  const BrandState = useContext(BrandStateContext);

  /**
   * HANDLE FORM INPUT
   * function to store the data in Immer object
   * validates that the field is not empty only after user has
   * typed inside before
   */
  function handleFormInput(type, input) {
    switch (type) {
      case "name":
        setFormValues(draft => {
          draft.name.value = input;
          draft.name.error = draft.name.value == "" ? "Name is required" : "";
        });
        break;

      case "email":
        setFormValues(draft => {
          draft.email.value = input;
        });
        break;

      case "contact":
        setFormValues(draft => {
          draft.contact.value = input;
          draft.contact.error = draft.contact.value == "" ? "Contact is required" : "";
        });
        break;

      case "message":
        setFormValues(draft => {
          draft.message.value = input;
          draft.message.error = draft.message.value == "" ? "Message is required" : "";
        });
        break;

      default:
        break;
    }
  }

  /**
   * Delay before validating the email field
   * confirms that the value is an email, kept as simple as possible to allow as many emails to pass
   */
  useEffect(() => {
    const delay = setTimeout(() => {
      // check if there is any value present
      if (formValues.email.value) {
        // validate value is an email
        setFormValues(draft => {
          draft.email.error = !/^\S+@\S+\.\S+$/.test(formValues.email.value) ? "Please enter a valid email" : "";
        });
      }
      // if not remove error
      else {
        setFormValues(draft => {
          draft.email.error = "";
        });
      }
    }, 800);
    return () => clearTimeout(delay);
  }, [formValues.email.value]);

  /**
   * Delay before validating the contact field
   * confirms that there are only an optional plus sign at the front and digits
   */
  useEffect(() => {
    const delay = setTimeout(() => {
      // check if there is any value present
      if (formValues.contact.value) {
        // validate value is an email
        setFormValues(draft => {
          draft.contact.error = !/^\+?(\d|\s)+$/.test(formValues.contact.value) ? "Contact should only contain digits" : "";
        });
      }
      // if not remove error
      else {
        setFormValues(draft => {
          draft.contact.error = "";
        });
      }
    }, 800);
    return () => clearTimeout(delay);
  }, [formValues.contact.value]);

  /**
   * FORM VALIDATIONS
   * validates that there is a value in each form field
   * only if all fields have no error then attempt to send form
   */
  function handleSubmit(e) {
    e.preventDefault();

    if (!formValues.name.value) {
      setFormValues(draft => {
        draft.name.error = "Name is required";
      });
    }

    if (!formValues.email.value) {
      setFormValues(draft => {
        draft.email.error = "Email is required";
      });
    }

    if (!formValues.contact.value) {
      setFormValues(draft => {
        draft.contact.error = "Contact is required";
      });
    }

    if (!formValues.message.value) {
      setFormValues(draft => {
        draft.message.error = "Message is required";
      });
    }

    if (!formValues.name.error && !formValues.email.error && !formValues.contact.error && !formValues.message.error) {
      const SubmitRequest = Axios.CancelToken.source();

      async function submitForm() {
        setFormValues(draft => {
          draft.isSubmitting = true;
        });

        try {
          const { data } = await Axios.post(
            `http://localhost:8888/wp-json/asmara/v1/contactform`,
            {
              name: formValues.name.value,
              email: formValues.email.value,
              contact: formValues.contact.value,
              message: formValues.message.value,
              recipient: BrandState ? BrandState.email_recipient : null,
            },
            SubmitRequest.token
          );

          if (data === true) {
            setFormValues(draft => {
              draft.resultMessage = "Form submitted successfully";
              draft.resultStatus = "success";
            });
          } else {
            setFormValues(draft => {
              draft.resultMessage = "Error in submitting form. Please try again.";
              draft.resultStatus = "error";
            });
          }
        } catch (error) {
          setFormValues(draft => {
            draft.resultMessage = `Error in submitting form: ${error}`;
            draft.resultStatus = "error";
          });
        }
      }

      submitForm();
    }
  }

  /**
   * Display form success/error message
   * message will display for 2 seconds before overlay will disappear
   * reset the form
   */
  useEffect(() => {
    setTimeout(() => {
      setFormValues(draft => {
        draft.isSubmitting = false;
        draft.name.value = "";
        draft.email.value = "";
        draft.contact.value = "";
        draft.message.value = "";
      });
    }, 5000);
  }, [formValues.resultMessage]);
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <>
      <div className="pre-footer"></div>
      <section id="footer" className="section-wrapper">
        <div className="footer-content-wrapper">
          <div className="container" id="contact">
            <div className="row">
              <div className="footer-content">
                <div className="section-header">
                  Have a <span className="highlight--green">question</span> or simply need to <span className="highlight--green">connect</span>? Drop
                  us a call or a message through the provided form.
                </div>
                {Boolean(props.type === "home") && <FooterHomeContent />}
                {Boolean(props.type === "brand") && <FooterBrandContent />}
              </div>

              {/* Form Section */}
              <div className="form-wrapper">
                <div className={`form-group ${Boolean(formValues.name.error) ? "error" : ""}`}>
                  <label htmlFor="name">Your Name {Boolean(formValues.name.error) && `- ${formValues.name.error}`}</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    value={formValues.name.value}
                    onChange={e => handleFormInput("name", e.target.value)}
                  />
                </div>

                <div className={`form-group ${Boolean(formValues.email.error) ? "error" : ""}`}>
                  <label htmlFor="email">Email {Boolean(formValues.email.error) && `- ${formValues.email.error}`}</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Enter your email address"
                    value={formValues.email.value}
                    onChange={e => handleFormInput("email", e.target.value)}
                  />
                </div>

                <div className={`form-group ${Boolean(formValues.contact.error) ? "error" : ""}`}>
                  <label htmlFor="contact">Contact Number {Boolean(formValues.contact.error) && `- ${formValues.contact.error}`}</label>
                  <input
                    type="text"
                    id="contact"
                    placeholder="Enter your phone number"
                    value={formValues.contact.value}
                    onChange={e => handleFormInput("contact", e.target.value)}
                  />
                </div>

                <div className={`form-group ${Boolean(formValues.message.error) ? "error" : ""}`}>
                  <label htmlFor="message">Message {Boolean(formValues.message.error) && `- ${formValues.message.error}`}</label>
                  <textarea
                    id="message"
                    rows="5"
                    placeholder="Your message here..."
                    value={formValues.message.value}
                    onChange={e => handleFormInput("message", e.target.value)}
                  ></textarea>
                </div>

                <div className="form-group">
                  <button onClick={handleSubmit}>
                    SUBMIT
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <g id="XMLID_1_">
                        <polyline id="XMLID_3_" points="0,263.9 506.5,263.9 506.5,248.1 0,248.1" />
                        <polygon id="XMLID_4_" points="439.5,334 512,261.5 512,250.5 439.5,178 428.5,189 494.7,256 428.5,323" />
                      </g>
                    </svg>
                  </button>
                </div>
                {Boolean(formValues.isSubmitting) && <FormLoader message={formValues.resultMessage} status={formValues.resultStatus} />}
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>Copyright &copy; 2020 | Asmara Lifestyle. All Rights Reserved</p>
        </footer>
      </section>
    </>
  );
}

export default Footer;
