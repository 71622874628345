import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppStateContext from "contexts/AppStateContent";
import Page from "components/Page";
import Banner from "components/Banner";
import { useImmer } from "use-immer";
import Loader from "components/Loader";
import BrandAbout from "./BrandAbout";
import BrandStateContext from "contexts/BrandStateContext";
import Footer from "components/Footer";
import Navigation from "components/Navigation";

function Brand(props) {
  const { slug } = useParams();

  const AppState = useContext(AppStateContext);

  const [brand, setBrand] = useImmer({});

  useEffect(() => {
    if (AppState.brands.length) {
      AppState.brands.forEach(item => {
        if (Boolean(item.slug == slug)) {
          // console.log(item);
          setBrand(draft => {
            draft.title = item.title.rendered;
            draft.banner = item.banner ? item.banner.large[0] : null;
            draft.content = item.content.rendered;
            draft.logo = item.acf.logo;
            draft.gallery = Object.values(item.acf.panel_images);
            draft.address = item.acf.address;
            draft.hours = item.acf.opening_hours;
            draft.contact = item.acf.contact_number;
            draft.email_recipient = item.acf.contact_email;
            draft.social = {
              facebook: item.acf.facebook || null,
              instagram: item.acf.instagram || null,
              googleplus: item.acf.googleplus || null,
              twitter: item.acf.twitter || null,
            };
            draft.menus = [
              { id: "menu_1", ...item.acf.menu_1 },
              { id: "menu_2", ...item.acf.menu_2 },
              { id: "menu_3", ...item.acf.menu_3 },
            ];
            draft.contentLoaded = true;
          });
        }
      });
    }
  }, [AppState.brands]);
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return brand.contentLoaded ? (
    <Page title={brand.title}>
      <BrandStateContext.Provider value={brand}>
        <Navigation />
        <Banner type="brand" image={brand.banner} />
        <BrandAbout />
        <Footer type="brand" />
      </BrandStateContext.Provider>
    </Page>
  ) : (
    <Loader />
  );
}

export default Brand;
