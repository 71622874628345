import React from "react";
import "css/Loader.css";
import loadingImage from "images/asmara-leaf.png";

function Loader(props) {
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <section id="loader" className="section-wrapper">
      <img src={loadingImage} alt="" />
    </section>
  );
}

export default Loader;
