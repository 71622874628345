import React from "react";

function BannerBrandContent(props) {
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <div>
      <div className="header">Brand &gt;</div>
      <div className="brand-name">{props.brand}</div>
    </div>
  );
}

export default BannerBrandContent;
