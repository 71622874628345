import React, { useEffect, useContext } from "react";
import AppStateContext from "contexts/AppStateContent";
import parse from "html-react-parser";
import "css/HomeAbout.scss";

function HomeAbout(props) {
  const AppState = useContext(AppStateContext);

  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <section id="about" className="section-wrapper">
      <div className="container">
        <div className="content-wrapper">
          <div className="title">
            <h4>About Us</h4>
          </div>
          <div className="content content--center">
            {Boolean(AppState.homepage.length) &&
              parse(String(AppState.homepage[0].content.rendered))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeAbout;
