import React from "react";
import { Link } from "react-router-dom";

function BannerHomeContent(props) {
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <>
      <div className="banner-content">Image - Club Med Spa</div>
      <div className="banner-scroll-icon">
        <Link to="about" className="down-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210.414 210.414" id="mouse">
            <path d="M105.207,0C66.532,0,35.069,31.45,35.069,70.104v70.21c0,38.653,31.463,70.1,70.138,70.1c38.675,0,70.138-31.446,70.138-70.1v-70.21C175.345,31.45,143.881,0,105.207,0z M167.552,140.314c0,34.357-27.968,62.307-62.345,62.307c-34.377,0-62.345-27.95-62.345-62.307v-70.21c0-34.357,27.968-62.311,62.345-62.311c34.377,0,62.345,27.953,62.345,62.311V140.314z" />
            <path d="M105.207,23.379c-2.152,0-3.897,1.743-3.897,3.897v35.286c0,2.154,1.745,3.897,3.897,3.897c2.152,0,3.897-1.743,3.897-3.897V27.276C109.103,25.122,107.359,23.379,105.207,23.379z" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.688 490.688" id="down">
            <path d="M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262L472.328,120.529z" />
            <path d="M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279C250.748,372.281,248.039,373.408,245.213,373.415z" />
          </svg>
        </Link>
      </div>
    </>
  );
}

export default BannerHomeContent;
