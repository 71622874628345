import React, { useContext, useEffect } from "react";

import "css/Banner.scss";
import BannerHomeContent from "./BannerHomeContent";
import BannerBrandContent from "./BannerBrandContent";
import BrandStateContext from "contexts/BrandStateContext";

function Banner(props) {
  const bannerImage = props.type === "brand" ? props.image : require(`images/${props.image}`);
  const BrandState = useContext(BrandStateContext);

  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <section
      id="banner"
      className="section-wrapper"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="banner-content--wrapper">
        <div className="container">{Boolean(props.type === "home") && <BannerHomeContent />}</div>
        <div className="container">
          {Boolean(props.type === "brand") && <BannerBrandContent brand={BrandState.title} />}
        </div>
      </div>
    </section>
  );
}

export default Banner;
