import React, { useEffect, useContext } from "react";
import "css/HomeBrands.scss";
import AppStateContext from "contexts/AppStateContent";
import { useHistory } from "react-router-dom";
import Leaf from "../../images/asmara-leaf.png";

function HomeBrands(props) {
  const AppState = useContext(AppStateContext);
  const history = useHistory();

  function handleClick(slug, url) {
    url === "" ? history.push(`brands/${slug}`) : (window.location.href = url);
  }

  useEffect(() => {
    console.log(AppState);
  }, [AppState]);

  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <section id="brands" className="section-wrapper">
      <div className="container">
        <div className="content-wrapper">
          <div className="title">
            <h4>Our Brands</h4>
          </div>
          <div className="content">
            {/* Need to add sync for acf.brandIntro here  */}

            <div className="brands--wrapper">
              {/* List out brands */}
              {!Boolean(AppState.brands.length)
                ? "Loading..."
                : AppState.brands.map(brand => {
                    return (
                      <div
                        className="brand"
                        key={brand.id}
                        style={{
                          backgroundImage: Boolean(brand.banner)
                            ? `url(${brand.banner.medium[0]})`
                            : `url(${Leaf})`,
                          backgroundSize: Boolean(brand.banner) || "50%",
                          backgroundPosition: Boolean(brand.banner) || "center",
                          backgroundColor: `#cecece`,
                        }}
                      >
                        <div className="brand-content">
                          <div className="header">{brand.header}</div>
                          <h4>{brand.title.rendered}</h4>
                        </div>

                        <div
                          className="brand--overlay"
                          onClick={e => handleClick(brand.slug, brand.acf.url)}
                        >
                          <div className="overlay-content">
                            <span className="see-more">
                              {brand.acf.url ? "Visit Site" : "See more"}
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <g id="XMLID_1_">
                                <polyline
                                  id="XMLID_3_"
                                  points="0,263.9 506.5,263.9 506.5,248.1 0,248.1"
                                />
                                <polygon
                                  id="XMLID_4_"
                                  points="439.5,334 512,261.5 512,250.5 439.5,178 428.5,189 494.7,256 428.5,323"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              {/* End List */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBrands;
