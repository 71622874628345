import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AppStateContext from "contexts/AppStateContent";
import Leaf from "../images/asmara-leaf.png";

function FooterHomeContent(props) {
  const AppState = useContext(AppStateContext);
  useEffect(() => {
    // console.log(AppState.brands);
  }, [AppState.brands]);
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <>
      <div className="home-content-wrapper">
        {Boolean(AppState.brands) &&
          AppState.brands.map(brand => {
            return (
              <div key={brand.id} className="logo-wrapper">
                <img
                  src={brand.acf.logo ? brand.acf.logo.sizes.medium : Leaf}
                  alt=""
                  style={{
                    maxWidth: Boolean(brand.acf.logo) || "50%",
                    margin: Boolean(brand.acf.logo) || "auto",
                  }}
                />

                <Link to={brand.acf.url ? { pathname: brand.acf.url } : `brands/${brand.slug}`} target={brand.acf.url ? "_blank" : ""}>
                  {brand.title.rendered}
                </Link>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default FooterHomeContent;
