import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Components

//CSS
import "css/App.scss";
import Home from "./components/Homepage/Home";
import Brand from "./components/Brandpage/Brand";
import AppStateContext from "./contexts/AppStateContent";
import AppDispatchContext from "./contexts/AppDispatchContext";
import { useImmerReducer } from "use-immer";
import Axios from "axios";

Axios.defaults.baseURL = process.env.REACT_APP_CMS_URL;

function App() {
  const initialState = {
    scrollPos: 0,
    brands: [],
    homepage: [],
    contentLoaded: false,
  };
  function reducer(draft, action) {
    switch (action.type) {
      case "updateValue":
        action.item === "scrollPos" && (draft.scrollPos = action.value);
        action.item === "brands" && (draft.brands = action.value);
        if (action.item === "homepage") {
          draft.homepage = action.value;
          draft.contentLoaded = true;
        }
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  // removing this for the time being as not needed
  // useEffect(() => {
  //   function handleScroll() {
  //     dispatch({ type: "updateValue", item: "scrollPos", value: window.pageYOffset });
  //   }

  //   window.addEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    // Fetch data from cms
    const BrandsRequest = Axios.CancelToken.source();

    async function fetchBrandsData() {
      try {
        const { data } = await Axios.get(
          `${process.env.REACT_APP_DEFAULT_ROUTE}brands`,
          BrandsRequest.token
        );

        dispatch({ type: "updateValue", item: "brands", value: data });
      } catch (error) {
        console.log(error);
      }
    }

    // call function
    fetchBrandsData();

    return () => {
      BrandsRequest.cancel();
    };
  }, []);

  useEffect(() => {
    // Fetch data from cms
    const HomeRequest = Axios.CancelToken.source();

    async function fetchHomeData() {
      try {
        const { data } = await Axios.get(
          `${process.env.REACT_APP_DEFAULT_ROUTE}pages?slug=homepage`,
          HomeRequest.token
        );

        dispatch({ type: "updateValue", item: "homepage", value: data });
      } catch (error) {
        console.log(error);
      }
    }

    // call function
    fetchHomeData();

    return () => {
      HomeRequest.cancel();
    };
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Switch>
            {/**
             * Homepage
             * passed the isHome prop so that Home component
             * does not render is as a 404 page
             * also will scroll to the top of page
             */}
            <Route path="/" exact>
              <Home isHome={true} />
            </Route>

            {/**
             * Brand Pages
             * Redirect to either existing brand page
             * or 404 page
             */}
            <Route path="/brands/:slug">
              <Brand />
            </Route>

            {/**
             * Admin route
             * Redirects to subdomain
             */}
            <Route
              path="/admin"
              exact
              render={() => {
                window.location = "https://admin.asmaralifestyle.com/wp-login.php";
              }}
            />

            {/**
             * All other pages
             * sends to homepage with :section parameter
             * Home component will render accordingly
             */}
            <Route path="/:section">
              <Home />
            </Route>
          </Switch>
        </BrowserRouter>
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

export default App;
