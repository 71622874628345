import React from "react";
import "./FormLoader.scss";

function FormLoader(props) {
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <div id="form-loader">{props.message ? <div className={`message ${props.status}`}>{props.message}</div> : <div className="loader"></div>}</div>
  );
}

export default FormLoader;
