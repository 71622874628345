import React, { useContext, useEffect } from "react";
import BrandStateContext from "contexts/BrandStateContext";
import BrandMenuFiles from "./BrandMenuFiles";
import parser from "html-react-parser";
import "css/BrandAbout.scss";

function BrandAbout(props) {
  const BrandState = useContext(BrandStateContext);
  useEffect(() => {
    console.log(BrandState.menus);
  }, []);

  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <section id="about-brand" className="section-wrapper">
      <div className="container">
        <div className="content-wrapper">
          <div className="title">
            <h4>About</h4>
          </div>
          <div className="content">
            <div className="row">
              <div className="brand-gallery">
                {BrandState.gallery.map(
                  image =>
                    Boolean(image) && (
                      <div className="panel-image" key={image.id}>
                        <img src={image.sizes.medium_large} alt="" />
                      </div>
                    )
                )}
              </div>

              <div className="brand-content">
                <div className="content-main">{parser(BrandState.content)}</div>

                {Boolean(BrandState.logo) && (
                  <div className="logo-wrapper">
                    <img
                      src={BrandState.logo.sizes.medium}
                      alt={BrandState.logo.alt != "" ? BrandState.logo.alt : BrandState.logo.title}
                    />
                  </div>
                )}

                {Boolean(BrandState.menus) && (
                  <div className="menus-wrapper">
                    {BrandState.menus.map(menu => {
                      return menu.title ? (
                        <div key={menu.id} className="menu">
                          <div className="menu-title">{menu.title}</div>
                          <div className="menu-description">
                            <p>{menu.description}</p>
                          </div>
                          <div className="menu-files">
                            {Boolean(menu.file_1) && (
                              <div className="file-wrapper">
                                <a href={menu.file_1.url} target="_blank">
                                  <span className="filename">{menu.file_1.title}</span>
                                  <span className="dl-icon">
                                    <svg
                                      viewBox="0 0 482.239 482.239"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path fill="black" d="m0 447.793h482.239v34.446h-482.239z" />
                                      <path d="m396.091 223.863-24.287-24.354-113.462 113.462v-312.971h-34.446v312.971l-113.495-113.496-24.22 24.354 154.938 155.073z" />
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            )}

                            {Boolean(menu.file_2) && (
                              <div className="file-wrapper">
                                <a href={menu.file_2.url} target="_blank">
                                  <span className="filename">{menu.file_2.title}</span>
                                  <span className="dl-icon">
                                    <svg
                                      viewBox="0 0 482.239 482.239"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path fill="black" d="m0 447.793h482.239v34.446h-482.239z" />
                                      <path d="m396.091 223.863-24.287-24.354-113.462 113.462v-312.971h-34.446v312.971l-113.495-113.496-24.22 24.354 154.938 155.073z" />
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            )}

                            {Boolean(menu.file_3) && (
                              <div className="file-wrapper">
                                <a href={menu.file_3.url} target="_blank">
                                  <span className="filename">{menu.file_3.title}</span>
                                  <span className="dl-icon">
                                    <svg
                                      viewBox="0 0 482.239 482.239"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path fill="black" d="m0 447.793h482.239v34.446h-482.239z" />
                                      <path d="m396.091 223.863-24.287-24.354-113.462 113.462v-312.971h-34.446v312.971l-113.495-113.496-24.22 24.354 154.938 155.073z" />
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BrandAbout;
