import React, { useEffect } from "react";

function Page(props) {
  useEffect(() => {
    document.title = `${props.title} | Asmara Lifestyle`;
    window.scrollTo(0, 0);
  }, []);
  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return (
    <>
      <div className={props.classes ? props.classes : ""}>{props.children}</div>
    </>
  );
}

export default Page;
