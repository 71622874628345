import React, { useEffect, useContext } from "react";
import Page from "../Page";
import HomeAbout from "./HomeAbout";
import HomeBrands from "./HomeBrands";
import Footer from "../Footer";
import { useParams, useHistory } from "react-router-dom";
import AppStateContext from "contexts/AppStateContent";
import Banner from "components/Banner";
import Navigation from "components/Navigation";
import Loader from "components/Loader";

function Home(props) {
  const { section } = useParams() || props.section;
  const AppState = useContext(AppStateContext);
  const history = useHistory();

  useEffect(() => {
    if (props.isHome) {
      window.scrollTo(0, 0);
    }

    if (section === "about") {
      if (document.querySelector("#about")) {
        document.querySelector("#about").scrollIntoView();
      } else {
        history.replace("/");
      }
    }

    if (section === "brands") {
      if (document.querySelector("#brands")) {
        document.querySelector("#brands").scrollIntoView();
      } else {
        history.replace("/");
      }
    }

    if (section === "contact") {
      if (document.querySelector("#footer")) {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        history.replace("/");
      }
    }
  }, [section]);

  /**
   *
   *
   *
   *
   * =======================================================
   * RETURN JSX
   * =======================================================
   */
  return AppState.contentLoaded ? (
    <Page title="Homepage">
      <Navigation />
      <Banner image="banner-1.jpg" type="home" />
      <HomeAbout />
      <HomeBrands />
      <Footer type="home" />
    </Page>
  ) : (
    <Loader />
  );
}

export default Home;
